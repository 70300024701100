import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AuthInterceptor } from './auth-interceptor.service';
import { ThankYouComponent } from './landing/thank-you/thank-you.component';

@NgModule({
  declarations: [
    AppComponent,
    TermsConditionsComponent,
    ThankYouComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: AuthInterceptor,
  //   multi: true,
  // },
  AuthGuard, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }


