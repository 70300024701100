import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../environments/environment";

@Injectable()
export class AuthService {
  authToken: any;
  token: any;
  baseUrl = environment.test;
  constructor(private http: HttpClient) {}

  login(data) {
    return this.http
      .post(this.baseUrl + "user/login", data)
      .toPromise()
      .then(res => res);
  }

  signUp(data){
    return this.http
    .post(this.baseUrl+ "user/signUp", data)
    .toPromise()
    .then(res => res)
  }

  forgotPassword(data) {
    return this.http
      .post(this.baseUrl + "user/forgot-password", data)
      .toPromise()
      .then(res => res);
  }

  getCountries(){
    return this.http
    .get(this.baseUrl+"user/country")
    .toPromise()
    .then(res => res);
  }

  getState(country){
    return this.http
    .get(this.baseUrl + "user/state/" + country)
    .toPromise()
    .then(res => res);
  }

  getProfile(){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "user/profile-info", headers).toPromise().then(res => res);
  }

  getNotifications(){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/get-notification", headers).toPromise().then(res => res); 
  }

  updateNotifications(){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/update-notification", headers).toPromise().then(res => res); 
  }

  editProfile(data){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "user/edit-info", data, headers).toPromise().then(res => res);
  }

  addDoctor(data){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "user/add-doctor", data, headers).toPromise().then(res => res);
  }

  addStaff(data){
    let headers = { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "user/add-staff", data, headers).toPromise().then(res => res);
  }

  getDocList(){
    return this.http
    .get(this.baseUrl + "user/doctors-list", { headers: { Authorization: localStorage.getItem("id_token") }
    }).toPromise().then(res => res);
  }

  getDetail(token){
    return this.http
    .get(this.baseUrl + "user/signup/" + token ).toPromise().then(res => res)
  }

  deleteDoctor(email){
    let data= {}
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    data["email"] = email;
    return this.http
    .post(this.baseUrl + "user/remove-doctor", data, headers).toPromise().then(res => res);
  }


  deleteStaff(data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "user/update-staffAccess", data, headers).toPromise().then(res => res);
  }

  getData(){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/form-data",  headers).toPromise().then(res => res);
  }


  checkCase(){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/case-allowed-check",  headers).toPromise().then(res => res);
  }

  // casesList(){
  //   let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
  //   return this.http
  //   .get(this.baseUrl + "cases/cases-list",  headers).toPromise().then(res => res);
  // }

  getArchiveCases(data?: any){
    let headers =  {params: data, headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/archive-case", headers).toPromise().then(res => res);
  }

  casesList(data?: any, filter?: any) {
    let headers =  { params: data, headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "cases/cases-list",filter,  headers).toPromise().then(res => res);
  }

  search(data?: any){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "cases/cases-list",data,  headers).toPromise().then(res => res);
  }

  caseSummary(id){
    let data = {_id: id}
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "cases/cases-list", data, headers).toPromise().then(res => res);
  }
  
  cancelCase(id:any){
    let data={_id:id}
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "admin/cancelStatus",data,headers).toPromise().then(res => res);
  }

  getDraft(){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/draft-cases",  headers).toPromise().then(res => res);
  }

  getUploadPending(){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/upload-pending-cases",  headers).toPromise().then(res => res);
  }

  uplaodPendingFiles(data) {
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http.post(this.baseUrl + "cases/add-pending-files", data, headers).toPromise().then(res => res);
  }

  completeDraft(id){
    let data =  {params: {_id: id}, headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/draft-cases/", data).toPromise().then(res => res);
  }

  addCase(data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http.post(this.baseUrl + "cases/add-cases", data, headers).toPromise().then(res => res);
  }

  addFilePendingCase(data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http.post(this.baseUrl + "cases/add-file-pending-cases", data, headers).toPromise().then(res => res);
  }

  uploadFile(id, file){
    let data =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .put(this.baseUrl + "cases/edit-case/" + id,file, data).toPromise().then(res => res);
  }

  editCase(id, data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .put(this.baseUrl + "cases/edit-case/" + id,data, headers).toPromise().then(res => res);
  }

  postComment(data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http.post(this.baseUrl + "cases/add-comment", data, headers).toPromise().then(res => res);
  }

  getComments(id){
    let data =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "cases/comment/" + id, data).toPromise().then(res => res);
  }

  deleteCard(id){
    let data =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .delete(this.baseUrl + "payment/deleteCard/" + id, data).toPromise().then(res => res);
  }

  setAsDefault(id,data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "payment/updateCard/" + id, data, headers).toPromise().then(res => res);
  }

  addCard(data){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http.post(this.baseUrl + "payment/addCard", data, headers).toPromise().then(res => res);
  }

  getPaymentList(){
    let headers =  { headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "payment/allCard",  headers).toPromise().then(res => res);
  }

  getInvoices(data?: any, filter?: any) {
    let headers =  { params: data, headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .post(this.baseUrl + "payment/invoice",filter,  headers).toPromise().then(res => res);
  }

  getPendingInvoice(data?: any) {
    let headers =  { params: data, headers: { Authorization:  localStorage.getItem("id_token") } };
    return this.http
    .get(this.baseUrl + "payment/pendinginvoice",  headers).toPromise().then(res => res);
  }

  storeUserData(token: string,monthlyPayment?:any) {
    localStorage.setItem("id_token", token);
    localStorage.setItem("month_check", monthlyPayment);
    this.authToken = token;
    console.log("stored",this.authToken);
  }

 
  loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
    return localStorage.getItem("id_token");
  }

  loggedIn() {
    return this.loadToken() !== null;
  }

  logout() {
    this.authToken = null;
    localStorage.clear();
  }
}
